import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import MainLayout from '../../layouts/MainLayout';
import { getPost } from '../../redux/reducers/newsAndEventSlice';
import format from 'date-fns/format';
import { Helmet } from 'react-helmet';

const SingleNews = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  const { newsAndEvents, isLoading } = useSelector((state) => state.newsAndEvents);

  useEffect(() => {
    dispatch(getPost(id));
  }, [dispatch, id]);

  return (
    <MainLayout
      title={`${newsAndEvents?.title} | ALFT Packaging (Pvt) Ltd`}
      description={newsAndEvents?.short_description}
      image={newsAndEvents?.main_image}
    >
      <Helmet>
        <meta property="og:description" content={newsAndEvents?.short_description} />
        <meta property="og:title" content={newsAndEvents?.title} />
        <meta property="og:image" content={newsAndEvents?.main_image} />
        <meta property="og:image:alt" content={newsAndEvents?.title} />
        <meta name="twitter:title" content={newsAndEvents?.title} />
        <meta name="twitter:description" content={newsAndEvents?.short_description} />
        <meta name="twitter:image" content={newsAndEvents?.main_image} />
      </Helmet>
      {isLoading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        <section className="blog-details container">
          <div className="category">
            {newsAndEvents?.category_id === 1 ? "News" :
              newsAndEvents?.category_id === 2 ? "Events" :
                newsAndEvents?.category_id === 3 ? "CSR Project" :
                  ""}
          </div>
          <div className="sub-heading">
            <h6>
              {newsAndEvents.created_at !== undefined
                ? format(new Date(newsAndEvents.created_at), 'd-MM-yyyy ')
                : ''}
            </h6>
            {/* <span></span> */}
            <h6>{newsAndEvents.authorName}</h6>
          </div>
          <h1 className="main-heading">{newsAndEvents.title}</h1>
          <img src={newsAndEvents?.main_image} alt="" />
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: newsAndEvents.long_description }}
          ></div>
        </section>
      )}
    </MainLayout>
  );
};

export default SingleNews;
