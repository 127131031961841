import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';

function CustomDropdown({ selected, handleSelect }) {
  const [isActive, setIsActive] = useState(false);
  const options = ['All Categories', 'News', 'Events', 'CSR Project'];
  const btnRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return () => {
      document.body.addEventListener('click', closeDropdown);
    };
  }, []);
  return (
    <div className="custom-select ">
      <div ref={btnRef} className="dropdown-btn" onClick={(e) => setIsActive(!isActive)}>
        {!selected ? 'Filter By Category' : selected}
        <svg
          width="12"
          height="16"
          viewBox="0 0 22 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 15.334C10.5745 15.3318 10.1548 15.235 9.77143 15.0505C9.38802 14.866 9.05049 14.5985 8.78333 14.2673L1.76666 5.76732C1.35665 5.2556 1.09864 4.63899 1.02202 3.98776C0.945407 3.33654 1.05327 2.67689 1.33333 2.08398C1.56046 1.56869 1.93112 1.12967 2.40103 0.819355C2.87094 0.509037 3.42024 0.340539 3.98333 0.333984H18.0167C18.5798 0.340539 19.1291 0.509037 19.599 0.819355C20.0689 1.12967 20.4395 1.56869 20.6667 2.08398C20.9467 2.67689 21.0546 3.33654 20.978 3.98776C20.9014 4.63899 20.6433 5.2556 20.2333 5.76732L13.2167 14.2673C12.9495 14.5985 12.612 14.866 12.2286 15.0505C11.8451 15.235 11.4255 15.3318 11 15.334Z"
            fill="#0083B2"
          />
        </svg>
      </div>
      {isActive && (
        <div className="dropdown-content">
          {options.map((option, index) => (
            <div
              key={index}
              onClick={(e) => {
                handleSelect(index);
                setIsActive(false);
              }}
              className="dropdown-item"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;
