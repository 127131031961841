import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import newsAndEventService from '../services/newsAndEventService';

const initialState = {
  newsAndEvents: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ''
};

// Get all job listings
export const getAllPosts = createAsyncThunk('news-and-events', async (_, thunkAPI) => {
  try {
    const data = await newsAndEventService.getAllPosts();
    // const filteredData = data.filter((d) => d.status === 'approved');
    return data.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Get single news and event
export const getPost = createAsyncThunk('news-and-events/single', async (id, thunkAPI) => {
  try {
    return await newsAndEventService.getPost(id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const newsAndEventSlice = createSlice({
  name: 'news-and-events',
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPosts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newsAndEvents = action.payload;
      })
      .addCase(getAllPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newsAndEvents = action.payload;
      })
      .addCase(getPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = newsAndEventSlice.actions;
export default newsAndEventSlice.reducer;
