import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ blog }) => {
  return (
    <article className="blog-card ">
      <Link to={`/news-and-events/${blog.id}`}>
        <img src={blog?.main_image} className="img-fluid" alt="" />
      </Link>

      <div className="content">
        <div className="d-flex justify-content-between align-items-center">
          <div className="category">
            {blog?.category_id === 1 ? "News" :
              blog?.category_id === 2 ? "Events" :
                blog?.category_id === 3 ? "CSR Project" :
                  ""}
          </div>
          <div className="date">{new Date(blog?.created_at).toLocaleDateString()}</div>
        </div>
        <Link to={`/news-and-events/${blog.id}`}>
          <h4>
            {blog?.title?.length > 100
              ? blog.title.substring(0, 100) + "..."
              : blog.title}
          </h4>
        </Link>

        <Link to={`/news-and-events/${blog.id}`}>
          <button>Read More</button>
        </Link>
      </div>
    </article>
  );
};

export default BlogCard;
